import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export const store = createStore({
  state () {
    return {
      login_id:'',
      login_password:'',
      login_token: null,
      user_id:'',
      user_name:'',
      authority_id:'',
      salt:"$2a$10$PPTh3GQUq02n64t6qm2/ke",
      report_data:{},
      property_name:"",
      reference_id:[],
      folder_name:'',
      report_status:'',
      allow_property: []
    }
  },
  mutations: {
    property_name(state,property_name){
      state.property_name = property_name;
    },
    property_institutions(state,institutions){
      state.institutions = institutions;
    },
    change_id: function (state, new_login_id) {
      state.login_id = new_login_id; 
    },
    change_password: function (state, new_login_password) {
      state.login_password = new_login_password; 
    },
    change_token: function (state, new_token) {
      state.login_token = new_token; 
    },
    change_user_id: function (state, new_user_id) {
      state.user_id = new_user_id; 
    },
    change_user_name: function (state, new_user_name) {
      state.user_name = new_user_name; 
    },
    change_authority_id: function (state, new_authority_id) {
      state.authority_id = new_authority_id; 
    },
    change_report_data: function (state, data) {
      state.report_data = data; 
    },
    reference_id: function (state, data) {
      state.reference_id = data; 
    },
    folder_name: function(state, folder_name) {
      state.folder_name = folder_name;
    },
    report_status: function(state, report_status) {
      state.report_status = report_status;
    },
    allow_property: function (state, allow_property) {
      state.allow_property = allow_property; 
    },
  },
  plugins: [createPersistedState({ // ストレージのキーを指定
    paths:[
    ],
    // ストレージの種類を指定
    storage: window.sessionStorage
  }),
  createPersistedState({
    paths: [
      'login_id',
      'login_password',
      'report_data',
      'user_id',
      'user_name',
      'login_token',
      'authority_id',
      'property_name',
      'reference_id',
      'folder_name',
      'report_status',
      'allow_property'
    ],
    storage: window.localStorage
  })],
  
})

