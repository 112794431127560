<template>
<div>
    <pc_head title="ユーザ詳細" path="/pc/user/list"></pc_head>
    <div class="wrapper">
        <div class="user_info">
            <div class="info_list">
                <div id="name_title" class="box_left">氏名</div>
                <div id="name" class="box_right">{{ user.name }}</div>
                <div id="email_title" class="box_left">ID（メールアドレス）</div>
                <div id="email" class="box_right">{{ user.email }}</div>
                <div id="authority_title" class="box_left">権限</div>
                <div id="authority" class="box_right">{{ user_authority }}</div>
                <div id="date_title" class="box_left">登録日時</div>
                <div id="date" class="box_right">{{ user.date }}</div>
            </div>
        </div>
    </div>
    <div class="btn_list">
        <button class="orange_btn" @click="update_flg_change('open')">編集する</button>
        <div v-if="update_flg" class="modal">
            <div class="modal_content">
                <h2 class="modal_title">ユーザ情報を編集する</h2>
                <div class="update_info">
                    <div class="update_list">
                        <div id="update_name" class="flex">
                            <div id="name_title" class="box_left update_left">氏名</div>
                            <input id="name" class="box_right update_right" v-model="edit_user_name" required>
                        </div>
                        <div id="update_email" class="flex">
                            <div id="email_title" class="box_left update_left">ID（メールアドレス）</div>
                            <input id="email" class="box_right update_right" v-model="edit_user_email" required>
                        </div>
                        <div id="update_name" class="flex">
                            <div id="authority_title" class="box_left update_left">権限</div>
                            <select id="authority_select" v-model="edit_user_authority_id" class="update_right" required>
                                <option value="" hidden>{{ user.authority_id }}</option>
                                <option value="0001">管理者</option>
                                <option value="0002">正社員</option>
                                <option value="0003">パート・アルバイト</option>
                            </select>
                        </div>
                        <div id="update_property" class="flex">
                            <div id="authority_update_title" class="box_left update_left">参照可能物件</div>
                            <select id="authority_select" v-model="selectedProperty" class="update_right" multiple required>
                                <option v-for="propertyInfo in property" :value="propertyInfo.property_id" >{{ propertyInfo.info.name }}</option>
                            </select>
                        </div>
                        <p v-if="isID" style="color:red">このIDはすでに存在しています</p>
                    </div>
                </div>
                <button class="orange_btn modal_orange_btn" @click="edit_user">確定する</button>
                <button class="modal_close" @click="update_flg_change">キャンセル</button>
            </div>
        </div>
        <button id="delete_btn" class="orange_btn" style="background-color: #1a3f66ff;" @click="delete_flg_change">削除する</button>
        <div v-if="delete_flg" class="modal">
            <div class="modal_content">
                <h2 class="modal_title">ユーザを削除する</h2>
                <p id="delete_info">氏名:{{ user.name }} <br />
                    ID（メールアドレス）:{{ user.email }}<br />
                    を削除しますがよろしいですか？
                </p>
                <button class="modal_btn orange_btn" @click="delete_user">削除</button>
                <button class="modal_close" @click="delete_flg_change">キャンセル</button>
            </div>
        </div>
    </div>
</div>
</template>

<script scoped>
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue"

export default ({
    components: {
        pc_head
    },
    mixins: [Mixin],
    data() {
        return {
            user: {},
            property: [],
            selectedProperty: [],
            edit_user_name: "",
            edit_user_email: "",
            edit_user_authority_id: "",
            user_authority: "",
            delete_flg: false,
            update_flg: false,
            isID: false,
        }
    },
    methods: {
        async delete_user() {
            const delete_user_id = { user_id: this.user.user_id };
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
                body: JSON.stringify({ user: delete_user_id })
            };
            // ユーザ削除
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/delete/" + this.$route.query.user_id, param)
            if (res.status == 200) {
                this.move_screen('/pc/user/list');
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
        },
        async edit_user() {
            this.user.name = this.edit_user_name;
            this.user.email = this.edit_user_email;
            this.user.authority_id = this.edit_user_authority_id;
            this.user.property =　this.selectedProperty;
            switch (this.user.authority_id) {
                case "0001":
                    this.user.authority_id = "0001"
                    break;
                case "0002":
                    this.user.authority_id = "0002"
                    break;
                case "0003":
                    this.user.authority_id = "0003"
                    break;
            }
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
                body: JSON.stringify({ user: this.user })
            };
            // 登録済みのメールアドレス
            var user_list = this.$store.state.reference_id;
            // ID(メールアドレス)重複判定
            for (var i = 0; i < user_list.length; i++) {
                if (user_list[i].email === this.user.email && user_list[i].user_id !== this.user.user_id) {
                    this.isID = true
                    return;
                } 
            }
            // ユーザ編集リクエスト
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/update/" + this.$route.query.user_id, param)
            if (res.status == 200) {

                location.reload()
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }


        },
        delete_flg_change() {
            this.delete_flg = !this.delete_flg
        },
        update_flg_change(flg) {
            if(flg === 'open'){
                console.log('open')
                this.update_flg = true; 
            } else {
                console.log('close')
                this.edit_user_name = this.user.name;
                this.edit_user_email = this.user.email;
                this.edit_user_authority_id = this.user.authority_id;
                this.update_flg = false;
            }
        },
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // ユーザー情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/" + this.$route.query.user_id, param)
        if (res.status == 200) {
            var data = await res.json()
            switch (data.user.authority_id) {
                case "0001":
                    this.user_authority = "管理者"
                    break;
                case "0002":
                    this.user_authority = "正社員"
                    break;
                case "0003":
                    this.user_authority = "パート・アルバイト"
                    break;
            }
            this.user = data.user
            this.edit_user_name = data.user.name;
            this.edit_user_email = data.user.email;
            this.edit_user_authority_id = data.user.authority_id;
            this.selectedProperty = data.user.property;
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }

        // 物件情報一覧取得
        const res2 = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
        if (res2.status == 200) {
            var data = await res2.json()
            this.property = data.property
            this.selectedProperty = this.user.property;
        } else if (res2.status == 401) {
            this.unauth()
        } else {
            this.other_error(res2.status)
        }
    },
})
</script>
  
<style scoped>
li {
    list-style: none;
}

.info_list {
    margin: 0 auto;
    display: grid;
    align-items: flex-end;
    font-weight: bold;
    grid-template-rows: repeat(2, 40px);
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        "area11 area12 area13 area14 area15 area16"
        "area21 area22 area23 area24 area25 area26";
}

.btn_list {
    margin-top: 50px;
}

#delete_info {
    margin-top: 75px;
}

.modal_content {
    height: 70%;
}

.update_list {
    margin-top: 30px;
}

.flex {
    display: flex;
    margin-top: 15px;
}

.update_left {
    width: 30%
}

.update_right {
    width: 70%;
}

#email_title {
    font-size: 14px;
}

#authority_update_title {
    width: 29.5%;
}

.margin {
    margin-left: 20px;
}
</style>