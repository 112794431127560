<template>
<div>
    <pc_head title="ユーザ一覧" path="/pc/system/management"></pc_head>
    <div class="wrapper">
        <ul class="user_list">
            <li class="user_item" v-for="user in users" :key="user" @click="move_description_screen(user)">
                <a class="user_id">{{ user.user_id }}</a>
                <a class="user_name">{{ user.name }}</a>
                <a class="user_email">{{ user.email }}</a>
            </li>
        </ul>
    </div>
    <button class="orange_btn add_orange_btn" style="width:30%" @click="open_add_modal">ユーザを追加する</button>
    <div v-if="add_flg" class="modal">
        <div class="modal_content">
            <h2 class="modal_title">ユーザを追加する</h2>
            <div class="update_info">
                <div class="update_list">
                    <div id="update_name" class="flex">
                        <div id="name_title" class="box_left update_left">氏名</div>
                        <input id="name" class="box_right update_right" v-model="user.name" placeholder="氏名" required>
                    </div>
                    <div id="update_email" class="flex">
                        <div id="email_title" class="box_left update_left">ID（メールアドレス）</div>
                        <input id="email" class="box_right update_right" v-model="input_email" :placeholder="user.email" required>
                    </div>
                    <div id="update_password" class="flex">
                        <div id="password_title" class="box_left update_left">パスワード</div>
                        <input id="password" type="password" class="box_right update_right" v-model=input_password placeholder="パスワード" required>
                    </div>
                    <div id="update_name" class="flex">
                        <div id="authority_update_title" class="box_left update_left">権限</div>
                        <select id="authority_select" v-model="user.authority_id" class="update_right" required>
                            <option value="0000" hidden>権限を選択</option>
                            <option value="0001">管理者</option>
                            <option value="0002">正社員</option>
                            <option value="0003">パート・アルバイト</option>
                        </select>
                    </div>
                    <div id="update_property" class="flex">
                        <div id="property_update_title" class="box_left update_left">参照可能物件</div>
                        <select id="property_select" v-model="selectedProperty" class="update_right" @change="eventselect" multiple required>
                            <option v-for="propertyInfo in property" :value="propertyInfo.property_id" >{{ propertyInfo.info.name }}</option>
                        </select>
                     </div>
                    <p v-show="error_flg_enough" style="color:red">全ての項目を設定してください</p>
                    <p v-show="error_flg_already" style="color:red">そのIDはすでに登録されています</p>
                </div>
            </div>
            <button class="orange_btn modal_orange_btn" @click="add_user">追加する</button>
            <button class="modal_close margin" @click="add_flg_change">キャンセル</button>
        </div>
    </div>
</div>
</template>
<script scoped>
import bcrypt from 'bcryptjs';
import { Mixin } from "../mixin";
import pc_head from "./pc_header.vue"


export default ({
    mixins: [Mixin],
    components: {
        pc_head
    },
    data() {
        return {
            // ユーザ一覧情報
            users: [],
            // テンプレート＆追加後情報
            user: {},
            property: [],
            selectedProperty: [],
            add_flg: false,
            salt_rounds: 10,
            input_email: '',
            input_password: '',
            authority_flg:false,
            error_flg_enough:false,
            error_flg_already:false,
            data:"",
        }
    },
    methods: {
        async add_user() {
            if (!this.user.name || !this.input_email || !this.input_password || this.user.authority_id == "0000") {
                this.error_flg_enough = true;
            } else {
                for (var i = 0; i < this.data.user.length; i++) {
                    if (this.data.user[i].email !== this.input_email) {
                        this.user.email = this.input_email;
                    } else {
                        this.error_flg_already = true;
                        return ;
                    }
                }
                this.user.property = this.selectedProperty;
                this.user.password = bcrypt.hashSync(this.input_password, this.$store.state.salt);
                this.user.delete_flg = false;
                // console.log(this.user)
                const param = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + this.$store.state.login_token
                    },
                    body: JSON.stringify({ user: this.user })
                };

                const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/add", param)
                if (res.status == 200) {
                    location.reload()
                } else if (res.status == 401) {
                    this.unauth()
                } else {
                    this.other_error(res.status)
                }
            }
        },
        move_description_screen(user) {
            this.$router.push({ path: '/pc/user', query: { user_id: user.user_id } })
        },
        async open_add_modal() {
            const template_param = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
            }
            // ユーザーテンプレートの取得
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/template", template_param)
            if (res.status == 200) {
                var data = await res.json()
                // console.log(data)
                this.user = data.user;
                this.add_flg_change()
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }

        },
        add_flg_change() {
            this.add_flg = !this.add_flg
            this.error_flg_enough = false;
            this.error_flg_already = false;
        },
        eventselect() {
            console.log(this.selectedProperty)
        },
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // ユーザー情報一覧の取得
        const res1 = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/list", param)
        if (res1.status == 200) {
            // レスポンス情報の取得
            this.data = await res1.json()
            this.$store.commit('reference_id', this.data.user);
            for (let i = 0; i < this.data.user.length; i++) {
                if (!this.data.user[i].delete_flg) {
                    this.users.push(this.data.user[i])
                }
            }
        } else if (res1.status == 401) {
            this.unauth()
        } else {
            this.other_error(res1.status)
        }

        // 物件情報一覧取得
        const res2 = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/list", param)
        if (res2.status == 200) {
            var data = await res2.json()
            this.property = data.property
        } else if (res2.status == 401) {
            this.unauth()
        } else {
            this.other_error(res2.status)
        }
    },
})

</script>

<style scoped>
.add_btn {
    margin: 0;
    height: 50px;
}

.modal_content {
    height: 70%;
}

header {
    display: flex;
    margin-top: 60px;
}

.add_orange_btn {
    height: 50px;
}

.update_list {
    margin-top: 50px;
}

.flex {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.update_left {
    width: 30%
}

.update_right {
    width: 70%;
}

#authority_update_title {
    width: 29.5%;
}

.modal_close {
    margin-top: 20px;
}

.user_list {
    padding: 0px;
}

.user_item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px gray solid;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    list-style: none;
    margin-top: 5px;
}

.user_id,
.user_name,
.user_email {
    width: 30%;
}

.user_item:hover {
    background-color: rgba(26, 63, 102, 0.100);
}
</style>