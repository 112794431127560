<template>
<div>
    <pc_head title="物件編集" :path="{path:'/pc/property/description',query: {property_id: this.$route.query.property_id}}"></pc_head>
    <div class="wrapper">
        <div class="property_details">
            <div class="property_info">
                <h2 class="info_title">物件名：<input v-model="receive_info.name" @input="onInputProperty" maxlength="50"></h2>
                <div class="info_list">

                    <div id="number_title" class="box_left">物件番号</div>
                    <input id="number" class="box_right" v-model="receive_info.number" :placeholder="receive_info.number" maxlength="10">

                    <div id="class_title" class="box_left">分類ステータス</div>
                    <input id="class" class="box_right" v-model="receive_info.section" :placeholder="receive_info.section" maxlength="50">

                    <div id="post_code_title" class="box_left">郵便番号</div>
                    <input id="post_code" class="box_right" v-model="receive_info.post_code" :placeholder="receive_info.post_code" maxlength="8">

                    <div id="prefecture_title" class="box_left" >都道府県</div>
                    <input id="prefecture" class="box_right" v-model="receive_info.prefecture" :placeholder="receive_info.prefecture" maxlength="5">

                    <div id="municipality_title" class="box_left">市区町村</div>
                    <input id="municipality" class="box_right" v-model="receive_info.municipality" maxlength="7" :placeholder="receive_info.municipality">

                    <div id="address_title" class="box_left">番地</div>
                    <input id="address" class="box_right" v-model="receive_info.address" maxlength="100" :placeholder="receive_info.address">

                    <div id="area_title" class="box_left">エリア</div>
                    <input id="area" class="box_right" v-model="receive_info.area" maxlength="50" :placeholder="receive_info.area">

                    <div id="line_title" class="box_left">沿線</div>
                    <input id="line" class="box_right" v-model="receive_info.line" maxlength="50" :placeholder="receive_info.line">

                    <div id="station_title" class="box_left">最寄り駅</div>
                    <input id="station" class="box_right" v-model="receive_info.station" maxlength="50" :placeholder="receive_info.station">

                    <div id="manager_title" class="box_left">物件担当</div>
                    <input id="manager" class="box_right" v-model="receive_info.person" maxlength="50" :placeholder="receive_info.person">

                    <div id="frequency_title" class="box_left">巡回頻度</div>
                    <input id="frequency" class="box_right" v-model="receive_info.frequency" maxlength="50" :placeholder="receive_info.frequency">

                    <div id="month_title" class="box_left">巡回実施予定月</div>
                    <input id="month" class="box_right" v-model="receive_info.month" maxlength="50" :placeholder="receive_info.month">

                    <div id="owner_title" class="box_left">オーナー名</div>
                    <input id="owner" class="box_right" v-model="receive_info.owner" @input="onInputOwner" maxlength="50" :placeholder="receive_info.owner">

                    <div id="subcontractor_title" class="box_left">下請け先</div>
                    <input id="subcontractor" class="box_right" v-model="receive_info.subcontractor" maxlength="50" :placeholder="receive_info.subcontractor">

                    <div id="canceled_title" class="box_left">契約状態</div>
                    <div v-if='receive_info.canceled_flg' id="canceled" class="box_right">解約済み</div>
                    <div v-else class="box_right">契約中</div>

                    <div id="auto_lock_title" class="box_left">オートロック番号</div>
                    <input id="auto_lock" class="box_right" v-model="receive_info.auto_lock" maxlength="50" :placeholder="receive_info.auto_lock">

                    <div id="key_box_title" class="box_left">キーボックスの場所</div>
                    <input id="key_box" class="box_right" v-model="receive_info.key_box" maxlength="100" :placeholder="receive_info.key_box">

                    <div id="remarks_title" class="box_left">特記事項</div>
                    <textarea id="remarks" class="box_right" v-model="receive_info.remarks" maxlength="1000" :placeholder="receive_info.remarks" cols="5"></textarea>
                    
                </div>
            </div>
            <div class="property_institution">
                <h1 id=" institution_title">施設一覧</h1>
                <div id="institution_list">
                    <div class="institution_item" v-for="institution in all_items" :key="institution">
                        <label>
                            <input type="checkbox" v-model="institution_items" @change="change_institution(institution)" :id="institution" :value="institution">{{ institution }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="btn_list">
        <button id="delete_btn" class="orange_btn" @click="edit_property">確定</button>
        <button id="edit_btn" class="orange_btn" @click="move_screen({path:'/pc/property/description',query: {property_id: this.$route.query.property_id}})">キャンセル</button>
    </div>
</div>
</template>

<script>
import { Mixin } from '../mixin'
import pc_head from "./pc_header.vue"


export default {
    components: {
        pc_head
    },
    mixins: [Mixin],
    data() {
        return {
            masta_owner_name:'',
            masta_property_name:'',
            receive_info: {},
            receive_institution: {},
            institution_items: [],
            all_items: ["エレベーター", "共用廊下", "外階段", "植栽", "機械式駐車場", "平置き駐車場", "ゴミ庫", "エントランス", "ラック式駐輪場", "平置き駐輪場", "平置きバイク置き場",
                "掲示板・掲示物", "メールボックス", "ゴミストッカー", "宅配ボックス", "管理室", "外観", "受水槽", "汚水槽", "外構門扉、余地部等", "高架水槽", "増圧ポンプ", "排水ポンプ", "消防設備", "空調設備", "電気工作物",
                "非常用エレベーター", "館銘板", "分電盤", "MDF盤", "内階段", "内廊下", "屋上", "地下室", "店舗", "事務所", "キッズルーム", "集会室", "ロッカールーム", "プレイロット", "AED", "自動販売機","外回り","集合郵便ポスト"],
            delete_flg: false,
        }
    },
    methods: {
        onInputProperty(event){
            // 入力された文字列に特定の文字が含まれている場合にはじく
            if (/[\\/:*?"<>|]/.test(event.target.value)) {
                this.receive_info.name = event.target.value.replace(/[\\/:*?"<>|]/g, "");
            }
        },
        onInputOwner(event){
            // 入力された文字列に特定の文字が含まれている場合にはじく
            if (/[\\/:*?"<>|]/.test(event.target.value)) {
                this.receive_info.owner = event.target.value.replace(/[\\/:*?"<>|]/g, "");
            }
        },
        // チェックボックスが押された際のデータの変更
        change_institution(val) {
            switch (val) {
                case "エレベーター":
                    this.change_flg("elevator");
                    break;
                case "共用廊下":
                    this.change_flg("hallway");
                    break;
                case "外階段":
                    this.change_flg("outside_stairs");
                    break;
                case "植栽":
                    this.change_flg("planting");
                    break;
                case "機械式駐車場":
                    this.change_flg("mechanical_parking");
                    break;
                case "平置き駐車場":
                    this.change_flg("flat_parking");
                    break;
                case "ゴミ庫":
                    this.change_flg("trash");
                    break;
                case "エントランス":
                    this.change_flg("entrance");
                    break;
                case "ラック式駐輪場":
                    this.change_flg("rack_b_parking");
                    break;
                case "平置き駐輪場":
                    this.change_flg("flat_b_parking");
                    break;
                case "平置きバイク置き場":
                    this.change_flg("flat_m_parking");
                    break;
                case "掲示板・掲示物":
                    this.change_flg("board");
                    break;
                case "メールボックス":
                    this.change_flg("mailbox");
                    break;
                case "ゴミストッカー":
                    this.change_flg("trash_stocker");
                    break;
                case "宅配ボックス":
                    this.change_flg("delivery_box");
                    break;
                case "管理室":
                    this.change_flg("janitor");
                    break;
                case "外観":
                    this.change_flg("tools");
                    break;
                case "受水槽":
                    this.change_flg("receiving_tank");
                    break;
                case "汚水槽":
                    this.change_flg("cesspool");
                    break;
                case "外構門扉、余地部等":
                    this.change_flg("septic_tank");
                    break;
                case "高架水槽":
                    this.change_flg("elevated_tank");
                    break;
                case "増圧ポンプ":
                    this.change_flg("booster_pump");
                    break;
                case "排水ポンプ":
                    this.change_flg("drainage_pump");
                    break;
                case "消防設備":
                    this.change_flg("firefight_equipment");
                    break;
                case "空調設備":
                    this.change_flg("air_conditioner");
                    break;
                case "電気工作物":
                    this.change_flg("electrical_work");
                    break;
                case "非常用エレベーター":
                    this.change_flg("emergency_elevator");
                    break;
                case "館銘板":
                    this.change_flg("disposer");
                    break;
                case "分電盤":
                    this.change_flg("distribution_board");
                    break;
                case "MDF盤":
                    this.change_flg("mdf_board");
                    break;
                case "内階段":
                    this.change_flg("inner_staircase");
                    break;
                case "内廊下":
                    this.change_flg("inner_corridor");
                    break;
                case "屋上":
                    this.change_flg("rooftop");
                    break;
                case "地下室":
                    this.change_flg("basement");
                    break;
                case "店舗":
                    this.change_flg("store");
                    break;
                case "事務所":
                    this.change_flg("office");
                    break;
                case "キッズルーム":
                    this.change_flg("kids_room");
                    break;
                case "集会室":
                    this.change_flg("assembly_room");
                    break;
                case "ロッカールーム":
                    this.change_flg("locker_room");
                    break;
                case "プレイロット":
                    this.change_flg("play_lot");
                    break;
                case "AED":
                    this.change_flg("aed");
                    break;
                case "自動販売機":
                    this.change_flg("vending_machine");
                    break;
                case "外回り":
                    this.change_flg("outer_circumference");
                    break;
                case "集合郵便ポスト":
                    this.change_flg("collective_mailbox");
                    break;
                default:
                    console.log("失敗しました");

            }
        },
        // 施設のtrue,falseの変更
        change_flg(val) {
            this.receive_institution[val] = !this.receive_institution[val];
        },
        // 編集内容を送信する
        async edit_property() {
            // 物件名が入力されていない場合
            if (!this.receive_info.name) {
                return window.alert("物件名を入力してください。")
            }
            // オーナー名が入力されていない場合
            if(!this.receive_info.owner){
                return window.alert("オーナー名を入力してください。")
            }
            // 物件名かオーナー名に変更がある場合ダイアログを出す。
            if ((this.receive_info.name !== this.masta_property_name) || (this.receive_info.owner !== this.masta_owner_name)) {
                // 編集時のダイアログをYes/Noで選択
                let checkSaveFlg = window.confirm('「物件名」または「オーナー名」を編集する場合、報告書の自動保存先が変わる場合があります。編集を実行してもよろしいですか？');
                if (checkSaveFlg) {
                    // Yes,フォルダの保存先が変わる場合、対応を催促する。
                    alert("編集を実行しました。報告書の自動保存先が変更されます。");
                } else {
                    // No
                    return alert("編集をキャンセルしました");
                }
            }
            const update_info = { info: this.receive_info, institutions: this.receive_institution };
            const param = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.$store.state.login_token
                },
                body: JSON.stringify({ property: update_info }),
            };
            // 物件情報更新
            const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/update/" + this.$route.query.property_id, param)
            if (res.status == 200) {
                var data = await res.json()
                // console.log(data);
                this.move_screen({ path: '/pc/property/description', query: { property_id: this.$route.query.property_id } });
            } else if (res.status == 401) {
                this.unauth()
            } else {
                this.other_error(res.status)
            }
            
        }
    },
    created: async function () {
        const param = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.$store.state.login_token
            },
        };
        // 物件情報取得
        const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/property/" + this.$route.query.property_id, param)
        if (res.status == 200) {
            var data = await res.json()
            // レスポンス情報の取得
            this.receive_info = data.property.info;
            this.masta_owner_name = data.property.info.owner;
            this.masta_property_name = data.property.info.name;
            this.receive_institution = data.property.institutions;
            this.property_id = data.property_id

            // 施設情報の有無を判断
            if (data.property.institutions.elevator) {
                this.institution_items.push("エレベーター");
            }
            if (data.property.institutions.hallway) {
                this.institution_items.push("共用廊下");
            }
            if (data.property.institutions.outside_stairs) {
                this.institution_items.push("外階段");
            }
            if (data.property.institutions.planting) {
                this.institution_items.push("植栽");
            }
            if (data.property.institutions.mechanical_parking) {
                this.institution_items.push("機械式駐車場");
            }
            if (data.property.institutions.flat_parking) {
                this.institution_items.push("平置き駐車場");
            }
            if (data.property.institutions.trash) {
                this.institution_items.push("ゴミ庫");
            }
            if (data.property.institutions.entrance) {
                this.institution_items.push("エントランス");
            }
            if (data.property.institutions.rack_b_parking) {
                this.institution_items.push("ラック式駐輪場");
            }
            if (data.property.institutions.flat_b_parking) {
                this.institution_items.push("平置き駐輪場");
            }
            if (data.property.institutions.flat_m_parking) {
                this.institution_items.push("平置きバイク置き場");
            }
            if (data.property.institutions.board) {
                this.institution_items.push("掲示板・掲示物");
            }
            if (data.property.institutions.mailbox) {
                this.institution_items.push("メールボックス");
            }
            if (data.property.institutions.trash_stocker) {
                this.institution_items.push("ゴミストッカー");
            }
            if (data.property.institutions.delivery_box) {
                this.institution_items.push("宅配ボックス");
            }
            if (data.property.institutions.janitor) {
                this.institution_items.push("管理室");
            }
            if (data.property.institutions.tools) {
                this.institution_items.push("外観");
            }
            if (data.property.institutions.receiving_tank) {
                this.institution_items.push("受水槽");
            }
            if (data.property.institutions.cesspool) {
                this.institution_items.push("汚水槽");
            }
            if (data.property.institutions.septic_tank) {
                this.institution_items.push("外構門扉、余地部等");
            }
            if (data.property.institutions.elevated_tank) {
                this.institution_items.push("高架水槽");
            }
            if (data.property.institutions.booster_pump) {
                this.institution_items.push("増圧ポンプ");
            }
            if (data.property.institutions.drainage_pump) {
                this.institution_items.push("排水ポンプ");
            }
            if (data.property.institutions.firefight_equipment) {
                this.institution_items.push("消防設備");
            }
            if (data.property.institutions.air_conditioner) {
                this.institution_items.push("空調設備");
            }
            if (data.property.institutions.electrical_work) {
                this.institution_items.push("電気工作物");
            }
            if (data.property.institutions.emergency_elevator) {
                this.institution_items.push("非常用エレベーター");
            }
            if (data.property.institutions.disposer) {
                this.institution_items.push("館銘板");
            }
            if (data.property.institutions.distribution_board) {
                this.institution_items.push("分電盤");
            }
            if (data.property.institutions.mdf_board) {
                this.institution_items.push("MDF盤");
            }
            if (data.property.institutions.inner_staircase) {
                this.institution_items.push("内階段");
            }
            if (data.property.institutions.inner_corridor) {
                this.institution_items.push("内廊下");
            }
            if (data.property.institutions.rooftop) {
                this.institution_items.push("屋上");
            }
            if (data.property.institutions.basement) {
                this.institution_items.push("地下室");
            }
            if (data.property.institutions.store) {
                this.institution_items.push("店舗");
            }
            if (data.property.institutions.office) {
                this.institution_items.push("事務所");
            }
            if (data.property.institutions.kids_room) {
                this.institution_items.push("キッズルーム");
            }
            if (data.property.institutions.assembly_room) {
                this.institution_items.push("集会室");
            }
            if (data.property.institutions.locker_room) {
                this.institution_items.push("ロッカールーム");
            }
            if (data.property.institutions.play_lot) {
                this.institution_items.push("プレイロット");
            }
            if (data.property.institutions.aed) {
                this.institution_items.push("AED");
            }
            if (data.property.institutions.vending_machine) {
                this.institution_items.push("自動販売機");
            }
            if (data.property.institutions.outer_circumference) {
                this.institution_items.push("外回り");
            }
            if (data.property.institutions.collective_mailbox) {
                this.institution_items.push("集合郵便ポスト");
            }
        } else if (res.status == 401) {
            this.unauth()
        } else {
            this.other_error(res.status)
        }
    },
}
</script>

<style scoped>
input {
    text-align: left;
    height: 50%;
}

.title_pc {
    margin-bottom: 0;
}

.info_title {
    text-align: center;
}

.property_info {
    margin-bottom: 50px;
}

.info_list {
    margin: 0 auto;
    display: grid;
    align-items: flex-end;
    font-weight: bold;
    grid-template-rows: repeat(8, 40px);
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
        "area11 area12 area13 area14 area15 area16"
        "area21 area22 area23 area24 area25 area26"
        "area31 area32 area33 area34 area35 area36"
        "area41 area42 area43 area44 area45 area46"
        "area51 area52 area53 area54 area55 area56"
        "area61 area62 area63 area64 area64 area64"
        "area71 area72 area72 area72 area72 area72"
        "area71 area72 area72 area72 area72 area72";
}

.box_right {
    font-weight: normal;
    margin-right: 5px;
    text-align: left;
}

.box_right::after {
    background-color: rgb(203, 203, 203);
    border-radius: 0px 5px 5px 0px;
    content: "";
    display: block;
    height: 3px;
}

.box_left::after {
    background-color: rgb(203, 203, 203);
    border-radius: 5px 0px 0px 5px;
    content: "";
    display: block;
    height: 3px;
}

#institution_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.institution_item {
    border-bottom: 3px solid rgb(203, 203, 203);
    width: 16%;
    margin-bottom: 3px;
    text-align: left;
}

.btn_list {
    width: 70%;
    margin: 0 auto;
    justify-content: space-around;
    display: flex;
}

.orange_btn {
    width: 20%;
}

.modal_content {
    width: 40%;
}

#delete_info {
    margin-top: 50px;
}

.modal_btn {
    border-radius: 20px;
    padding: 10px 40px;
}

#edit_btn {
    background-color: #1a3f66ff;
}


/* 以下情報詳細項目CSS */
#number_title {
    display: inline;
    grid-area: area11;
}

#number {
    grid-area: area12;
}

#class_title {
    grid-area: area13;
}

#class {
    grid-area: area14;
}

#post_code_title {
    grid-area: area15;
}

#post_code {
    grid-area: area16;
}

#prefecture_title {
    grid-area: area21;
}

#prefecture {
    grid-area: area22;
}

#municipality_title {
    grid-area: area23;
}

#municipality {
    grid-area: area24;
}

#address_title {
    grid-area: area25;
}

#address {
    grid-area: area26;
}

#area_title {
    grid-area: area31;
}

#area {
    grid-area: area32;
}

#line_title {
    grid-area: area33;
}

#line {
    grid-area: area34;
}

#station_title {
    grid-area: area35;
}

#station {
    grid-area: area36;
}

#manager_title {
    grid-area: area41;
}

#manager {
    grid-area: area42;
}

#frequency_title {
    grid-area: area43;
}

#frequency {
    grid-area: area44;
}

#month_title {
    grid-area: area45;
}

#month {
    grid-area: area46;
}

#owner_title {
    grid-area: area51;
}

#owner {
    grid-area: area52;
}

#subcontractor_title {
    grid-area: area53;
}

#subcontractor {
    grid-area: area54;
}

#auto_lock_title {
    grid-area: area61;
}

#auto_lock {
    grid-area: area62;
}

#key_box_title {
    grid-area: area63;
}

#key_box {
    grid-area: area64;
}

#remarks_title {
    grid-area: area71;
}

#remarks {
    grid-area: area72;
    background-color: #fff;
    font-size: 20px;
    font-weight: bold;
    resize: none;
    border-bottom: solid rgb(203, 203, 203) 3px;
    height: 80%;}
</style>