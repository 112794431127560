<template>
  <div class="container" id="login_page">
    <h1>巡回報告システム</h1>
    <main>
      <div class="login_id">
        <p>ログインID（メールアドレス）</p>
        <input type="email" class="input_box" placeholder="メールアドレスを入力してください" maxlength="250" v-model="login_id">
      </div>
      <div class="login_password">
        <p>パスワード</p>
        <input :type="inputType" class="input_box" minlength="6" maxlength="16" placeholder="パスワードを入力してください"
          v-model="login_password">
        <span :class="iconType" @click="onClick" class="icon">
          <img v-show="!isChecked" src="@/assets/eye_slash.png" alt="" width="25" height="25">
          <img v-show="isChecked" src="@/assets/eye.png" alt="" width="25" height="25">
        </span>
      </div>
      <p v-if="error_text" id="login_error">{{ error_text }}</p>
      <div class="login_keep">
        <label>
          <input type="checkbox" @change="login_keep_flg_change">
          <span>ログイン状態を保持する</span>
        </label>
      </div>
      <button id="login_btn" class="orange_btn" @click="data_submit()">ログイン</button>
    </main>

    <div class="login_tel">
      <p>ログインID、パスワードを忘れた方はこちら</p>
      <p>管理部 TEL:03-6908-9064</p>
    </div>
  </div>
</template>
<script>
import { Mixin } from '../mixin'
import bcrypt from 'bcryptjs';

export default ({
  mixins: [Mixin],
  data() {
    return {
      login_id: "",
      login_password: "",
      error_text: "",
      salt_rounds: 10,
      login_keep_flg: false,
      isChecked: false,
    }
  },
  methods: {
    async data_submit() {
      // パスワードのハッシュ化
      const hash = bcrypt.hashSync(this.login_password, this.$store.state.salt);
      // パスワードの暗号化
      const encrypt = window.btoa(this.login_password);
      // IDとパスワードをローカルストレージに保存
      this.$store.commit('change_id', this.login_id);
      this.$store.commit('change_password', encrypt);
      
      // ログインのAPIリクエスト
      const body_item = { user_id: this.login_id, user_password: hash, login_keep_flg: this.login_keep_flg }
      const param = {
        method: "POST",
        body: JSON.stringify(body_item),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': null
        },
      };
      var login_flg = false
      const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/login", param)
      // ステータスコードによって変更する
      if (res.status == 200) {
        login_flg = true
      } else if (res.status == 401) {
        this.unauth()
      } else {
        this.other_error(res.status)
      }
      var data = await res.json()
      // トークンをvuexに保存する
      if (login_flg) {
        const login_token = data.Authorization
        const user_id = data.user_id
        this.$store.commit('change_token', login_token)
        this.$store.commit('change_user_id', user_id)
        // 許可参照物件情報保存（PC版には影響しない）
        this.$store.commit('allow_property', data.property)
        // ユーザーの権限情報を取得する。ユーザ詳細情報取得API
        const param = {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + this.$store.state.login_token
          },
        };
        const res1 = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/" + user_id, param)
        if (res1.status == 200) {
          var data = await res1.json()
          const user_name = data.user.name
          const authority_id = data.user.authority_id;
          // ユーザ名をvuexに保存する
          this.$store.commit('change_user_name', user_name)
          // ユーザ権限IDをvuexに保存する
          this.$store.commit('change_authority_id', authority_id)
          // ユーザーの権限情報を取得する
          if (authority_id == "0001") {
            this.move_screen('/pc/top')
          } else if (authority_id == "0002") {
            this.move_screen('/pc/report/list')
          } else {
            this.error_text = "権限がありません"
          }
        } else if (res1.status == 401) {
          this.unauth()
        } else {
          this.other_error(res1.status)
        }
      }
    },
    login_keep_flg_change() {
      this.login_keep_flg = !this.login_keep_flg
      // console.log(this.login_keep_flg);
    },
    onClick() {
      this.isChecked = !this.isChecked;
    }
  },
  computed: {
    new_token() {
      return this.$store.state.login_token
    },
    inputType: function () {
      return this.isChecked ? "text" : "password";
    },
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    }
  },
  created: function () {
    // VuexのID/PASS/Tokenデータを参照
    var token = this.$store.state.login_token
    var user_name = this.$store.state.user_name;
    this.login_id = this.$store.state.login_id;
    this.login_password = window.atob(this.$store.state.login_password);

    // トークンを持っている際のページの遷移
    if (token != "" && token != null && token != undefined && user_name != "" && user_name != null && user_name != undefined) {
      if (this.$store.state.authority_id == "0001") {
        this.move_screen('/pc/top/')
      } else if (this.$store.state.authority_id == "0002") {
        this.move_screen('/pc/system/management')
      }
      // すでにログイン済みなので画面遷移する（もしTokenがないならTopに戻す）
    }
  },
})

</script>

<style scoped>
.orange_btn {
  width: 100px;
  margin: 50px auto 0 auto;
}

.container {
  text-align: center;
  height: 100%;
  margin-top: 60px;
}

main {
  margin: 0 auto;
  width: 40%;
}

h1 {
  margin: 0 0 3% 0;
}

p {
  margin: 3% 0px 1% 0px;
  font-size: 22px;
}

.input_box {
  width: 100%;
  padding: 1%;
  margin-bottom: 2%;
  border: black solid 1px;
}

.login_keep {
  margin-top: 1%;
  text-align: left;
  font-size: 16px;
}

.login_tel {
  margin-top: 3%;
}

.login_tel p {
  margin: 0px;
}

#login_error {
  color: red;
  margin: 0px;
  font-size: 12px;
}

/* eye_icon */
.login_password {
  position: relative;
}

.icon {
  position: absolute;
  right: -5px;
  top: 35px;
}

@media screen and (min-width: 770px) {
  .icon {
    top: 40px;
  }
}
</style>
