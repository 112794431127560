<template>
  <div class="container" id="login_page">
    <h1>巡回報告システム</h1>
    <main>
      <div class="login_id">
        <p>ログインID（メールアドレス）</p>
        <input type="email" class="input_box" placeholder="メールアドレスを入力してください" v-model="login_id" maxlength="250" required>
      </div>
      <div class="login_password">
        <p>パスワード</p>
        <input :type="inputType" class="input_box" minlength="6" maxlength="16" placeholder="パスワードを入力してください"
          v-model="login_password" required>
        <span :class="iconType" @click="onClick" class="icon">
          <img v-show="!isChecked" class="eye_icon" src="@/assets/eye_slash.png" alt="" width="30" height="30">
          <img v-show="isChecked" class="eye_icon" src="@/assets/eye.png" alt="" width="30" height="30">
        </span>
      </div>
      <p v-if="!login_flg" id="login_error">{{ error_text }}</p>
      <div class="login_keep">
        <label class="label">
          <input type="checkbox" @change="login_keep_flg_change">
          <p>ログイン状態を保持する</p>
        </label>
      </div>
      <button id="login_btn" class="orange_btn" @click="data_submit()">ログイン</button>
    </main>

    <div class="login_tel">
      <p>ログインID、パスワードを忘れた方はこちら</p>
      <p>管理部TEL： <a href="tel:0369089064">03-6908-9064</a></p>
    </div>
  </div>
</template>
<script>
import { Mixin } from '../mixin'
import bcrypt from 'bcryptjs';

export default ({
  mixins: [Mixin],
  data() {
    return {
      login_flg: false,
      login_id: "",
      login_password: "",
      error_text: "",
      salt_rounds: 10,
      login_keep_flg: false,
      isChecked: false,
    }
  },
  methods: {
    // 目のアイコンを変更
    onClick() {
      this.isChecked = !this.isChecked;
    },
    async data_submit() {

      // ログインボタンのアクション
      // パスワードのハッシュ化
      const hash = bcrypt.hashSync(this.login_password, this.$store.state.salt);

      // パスワードの暗号化
      const encrypt = window.btoa(this.login_password);

      // IDとパスワード,参照許可物件IDをローカルストレージに保存
      this.$store.commit('change_id', this.login_id);
      this.$store.commit('change_password', encrypt);

      // ログインのAPIリクエスト
      const body_item = { user_id: this.login_id, user_password: hash, login_keep_flg: this.login_keep_flg }
      const param = {
        method: "POST",
        body: JSON.stringify(body_item),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': null
        },
      };

      // API(/login)
      const res = await fetch(process.env.VUE_APP_API_BASE_URL + "/login", param)
      if (res.status == 200) {
        var data = await res.json()
        // ログインに成功していたら(ログインIDがあれば)
        if (this.$store.state.login_id != "") {

          // Token/UserIdをVuexに保存
          const login_token = data.Authorization
          const user_id = data.user_id
          this.$store.commit('change_token', login_token)
          this.$store.commit('change_user_id', user_id)
          this.$store.commit('allow_property', data.property)
          
          // ユーザーの権限情報を取得する。ユーザ詳細情報取得API
          const param = {
            method: "GET",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + this.$store.state.login_token
            },
          };
          const res1 = await fetch(process.env.VUE_APP_API_BASE_URL + "/user/" + user_id, param)
          if (res1.status == 200) {
            var data = await res1.json()
            const authority_id = data.user.authority_id;

            // ユーザ権限IDをvuexに保存する
            this.$store.commit('change_authority_id', authority_id)

            // 物件一覧画面に遷移
            this.move_screen('/property/list')
          } else if (res.status == 401) {
            this.unauth()
          } else {
            this.other_error(res.status)
          }
        }
      } 
      else {
        this.error_text = 'ログインIDまたはパスワードが間違っています '
      }
    },
    login_keep_flg_change() {
      this.login_keep_flg = !this.login_keep_flg
    }
  },
  computed: {
    new_token() {
      return this.$store.state.login_token
    },
    inputType: function () {
      return this.isChecked ? "text" : "password";
    },
    iconType: function () {
      return this.isChecked ? "eye-slash" : "eye";
    }
  },
  created: function () {

    // VuexのID/PASS/Tokenデータを参照
    var token = this.$store.state.login_token
    this.login_id = this.$store.state.login_id;
    this.login_password = window.atob(this.$store.state.login_password);

    // トークンを持っている際のページの遷移
    if (token != "") {
      // すでにログイン済みなので画面遷移する（もしTokenがないならTopに戻す）
      this.move_screen('/property/list')
    }
  },
})

</script>

<style scoped>
/* もともとあったコード */

.container {
  text-align: center;
  height: 100%;
}

main {
  margin: 0 auto;
}

h1 {
  margin: 0 0 3% 0;
}

.input_box {
  width: 100%;
  padding: 1%;
  margin-bottom: 2%;
  border: black solid 1px;
}

.login_keep {
  margin-top: 1%;
  font-size: 16px;
  margin: auto 0;
}

.login_tel {
  margin-top: 3%;
}

.login_tel p {
  margin: 0px;
}

#login_error {
  color: red;
  margin: 0px;
  font-size: 12px;
}


/* 書いたコード */
p {
  margin: 0;
}

.label {
  position: relative;
}

.label input {
  position: absolute;
  top: 0;
  left: -130px;
}

input {
  width: 20px;
  height: 20px;
}

.container h1 {
  margin-top: 70px;
  margin-bottom: 30px;
}

p {
  margin: 16px 0px 4px 0px;
  font-size: 18px;
}

/* ID,PASS */
.input_box {
  width: 80%;
  height: 35px;
  margin-bottom: 10px;
  border: 1px black solid;
  border-radius: 25px;
  text-indent: 10px;
}

input:focus {
  border: 1px solid rgb(91, 91, 255);
  background: #cacafa;
  outline: 0;
}

.login_tel p {
  margin: 0px;
}

.icon {
  position: relative;
}

.eye_icon {
  width: 30px;
  position: absolute;
  right: 10px;
  top: -5px;
}

.login_keep {
  margin-left: 10px;
}

.login_keep_label {
  position: relative;
}

.checkbox {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  top: -5px;
}

/* エラー文 */
#login_error {
  color: red;
  margin: 0px;
  font-size: 12px;
}

/*  ログインボタン */
.orange_btn:hover {
  background: #ff8935;
}

.orange_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 80%;
  height: 45px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  border-radius: 25px;
  transition: 0.3s;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}
</style>